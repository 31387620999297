/*
 * -------------------------------------------------------------------------------
 *
 * Copyright 2022 Valory AG
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * -------------------------------------------------------------------------------
 */

import React, { useState, useEffect } from 'react';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import {
  Typography, Button, Menu, Layout, Alert,
} from 'antd/lib';
import { useCheckMobileScreen } from 'common-util/hooks/useCheckMobileScreen';
import Footer from './Footer';
import { CustomLayout } from './styles';

const { Header: HeaderContainer, Content: AntdContent } = Layout;
const { Title } = Typography;

const LogoSvg = dynamic(() => import('common-util/SVGs/logo'));
const title = <Title>Oracles</Title>;

const Content = () => {
  const isMobile = useCheckMobileScreen();
  const router = useRouter();

  const [selectedMenu, setSelectedMenu] = useState([]);

  const { pathname } = router;

  // to set default menu on first render
  useEffect(() => {
    if (pathname) {
      const name = pathname.split('/')[1];
      setSelectedMenu(name || null);
    }
  }, [pathname]);

  const handleMenuItemClick = ({ key }) => {
    router.push(`/${key}`);
    setSelectedMenu(key);
  };

  return (
    <CustomLayout>
      <HeaderContainer className="header-container">
        <LogoSvg />
        {!isMobile && title}

        <Menu theme="light" mode="horizontal" selectedKeys={[selectedMenu]}>
          <Menu.Item key="price" onClick={handleMenuItemClick}>
            Price Oracle
          </Menu.Item>
          <Menu.Item key="ml-apy-prediction" onClick={handleMenuItemClick}>
            ML APY Prediction Oracle
          </Menu.Item>
        </Menu>

        <Button
          type="primary"
          size="large"
          onClick={() => window.open('https://www.olas.network')}
        >
          {`LEARN MORE${isMobile ? '' : ' ABOUT OLAS'}`}
        </Button>
      </HeaderContainer>

      <AntdContent className="site-layout">
        <div className="site-layout-background">
          <Alert
            style={{ fontSize: 28, maxWidth: 820, margin: '24px auto' }}
            message={(
              <Title level={3} className="m-0">
                This app has been deprecated and is no longer supported.
              </Title>
            )}
            type="info"
            showIcon
          />
        </div>
        <Footer />
      </AntdContent>
    </CustomLayout>
  );
};

export default Content;
